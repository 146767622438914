/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useMemo } from 'react';
import { Blockquote } from '@tiptap/extension-blockquote';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Code } from '@tiptap/extension-code';
import { CodeBlock } from '@tiptap/extension-code-block';
import { Color } from '@tiptap/extension-color';
import { Document } from '@tiptap/extension-document';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Highlight } from '@tiptap/extension-highlight';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { TaskItem } from '@tiptap/extension-task-item';
import { TaskList } from '@tiptap/extension-task-list';
import { Text } from '@tiptap/extension-text';
// import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuControlsContainer,
  MenuDivider,
  // MenuSelectTextAlign,
  TableBubbleMenu,
  LinkBubbleMenu,
  RichTextEditor,
  TableImproved,
  LinkBubbleMenuHandler,
} from 'mui-tiptap';
import { Box, FormHelperText, useTheme } from '@mui/material';

export default function RichTextField({
  value,
  onChange,
  isDisabled,
  error,
  minRows = 5,
}) {
  const theme = useTheme();
  const rteRef = useRef(null);
  const handleChange = () => {
    onChange(rteRef.current?.editor?.getHTML());
  };

  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  // Make subscript and superscript mutually exclusive
  // https://github.com/ueberdosis/tiptap/pull/1436#issuecomment-1031937768
  const CustomSubscript = Subscript.extend({
    excludes: 'superscript',
  });

  const CustomSuperscript = Superscript.extend({
    excludes: 'subscript',
  });

  const minHeight = useMemo(() => minRows * 28.8, [minRows]);

  return (
    <Box>
      <Box
        sx={error ? {
          border: 'red 1px solid',
          '& .MuiTiptap-RichTextContent-root': {
            minHeight,
          },
          '& .ProseMirror': {
            minHeight,
          },
        } : {
          '& .MuiTiptap-RichTextContent-root': {
            minHeight,
          },
          '& .ProseMirror': {
            minHeight,
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={[
            TableImproved.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,

            BulletList,
            CodeBlock,
            Document,
            HardBreak,
            ListItem,
            OrderedList,
            Paragraph,
            CustomSubscript,
            CustomSuperscript,
            Text,

            // Blockquote must come after Bold, since we want the "Cmd+B" shortcut to
            // have lower precedence than the Blockquote "Cmd+Shift+B" shortcut.
            // Otherwise using "Cmd+Shift+B" will mistakenly toggle the bold mark.
            // (See https://github.com/ueberdosis/tiptap/issues/4005,
            // https://github.com/ueberdosis/tiptap/issues/4006)
            Bold,
            Blockquote,

            Code,
            Italic,
            Underline,
            Strike,
            CustomLinkExtension.configure({
              // autolink is generally useful for changing text into links if they
              // appear to be URLs (like someone types in literally "example.com"),
              // though it comes with the caveat that if you then *remove* the link
              // from the text, and then add a space or newline directly after the
              // text, autolink will turn the text back into a link again. Not ideal,
              // but probably still overall worth having autolink enabled, and that's
              // how a lot of other tools behave as well.
              autolink: true,
              linkOnPaste: true,
              openOnClick: false,
            }),
            LinkBubbleMenuHandler,

            // Extensions
            Gapcursor,
            // Debug Later -- Text Align doesnt work
            // TextAlign.configure({
            //   types: ['heading', 'paragraph'],
            //   alignments: ['left', 'right'],
            // }),
            TextStyle,
            Color,
            Highlight.configure({ multicolor: true }),
            HorizontalRule,
            Dropcursor,

            TaskList,
            TaskItem.configure({
              nested: true,
            }),
          ]}
          content={value} // Initial content for the editor
          onUpdate={() => handleChange()}
          editable={!isDisabled}
          // Optionally include `renderControls` for a menu-bar atop the editor:
          renderControls={() => (
            <MenuControlsContainer>
              <MenuButtonBold />
              <MenuButtonItalic />
              <MenuButtonUnderline />
              <MenuButtonStrikethrough />
              <MenuButtonSubscript />
              <MenuButtonSuperscript />
              <MenuDivider />

              <MenuButtonTextColor
                defaultTextColor={theme.palette.text.primary}
                swatchColors={[
                  { value: '#000000', label: 'Black' },
                  { value: '#ffffff', label: 'White' },
                  { value: '#888888', label: 'Grey' },
                  { value: '#ff0000', label: 'Red' },
                  { value: '#ff9900', label: 'Orange' },
                  { value: '#ffff00', label: 'Yellow' },
                  { value: '#00d000', label: 'Green' },
                  { value: '#0000ff', label: 'Blue' },
                ]}
              />
              <MenuButtonHighlightColor
                swatchColors={[
                  { value: '#595959', label: 'Dark grey' },
                  { value: '#dddddd', label: 'Light grey' },
                  { value: '#ffa6a6', label: 'Light red' },
                  { value: '#ffd699', label: 'Light orange' },
                  // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
                  { value: '#ffff00', label: 'Yellow' },
                  { value: '#99cc99', label: 'Light green' },
                  { value: '#90c6ff', label: 'Light blue' },
                  { value: '#8085e9', label: 'Light purple' },
                ]}
              />
              <MenuDivider />

              <MenuButtonEditLink />
              <MenuDivider />

              {/* <MenuSelectTextAlign />
              <MenuDivider /> */}

              <MenuButtonOrderedList />
              <MenuButtonBulletedList />
              <MenuButtonTaskList />
              <MenuDivider />

              <MenuButtonBlockquote />
              <MenuDivider />

              <MenuButtonCode />
              <MenuDivider />

              <MenuButtonHorizontalRule />
              <MenuButtonAddTable />
              {/* Add more controls of your choosing here */}
            </MenuControlsContainer>
          )}
        >
          {() => (
            <>
              <TableBubbleMenu />
              <LinkBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>
      {error ? (
        <FormHelperText
          error
          required
          variant="filled"
        >
          {error}
        </FormHelperText>
      ) : null}
    </Box>
  );
}
