/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useContext, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  Box,
  FormHelperText,
  Typography,
  Divider,
  InputAdornment,
  MenuItem,
  Autocomplete,
  IconButton,
  Switch,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { PlatformContext } from '@upptic/module-directory';
import { useQuery } from '@apollo/client';
import { sortBy, uniqBy } from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AuditFields from '../../components/forms/AuditFields';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import Select from '../Select';
import CountrySelect from './CountrySelect';
import { HTMLInclude } from '../../components/forms/HTMLInclude';
import { Indicator } from '../../components/forms/Indicator';
import FormButtonWDynamicText from './FormButtonWDynamicText';
import { handleError } from './fieldError';
import LocaleSelect from './LocaleSelect';
import OptionBuilder from '../../components/OptionBuilder/OptionBuilder';
import DualList from '../DualList/DualList';
import SingleUploadInput from '../../components/forms/SingleUploadInput';
import { clientUsersGql } from '../gql';
import { UsersAutocomplete } from './UsersAutocomplete';
import TextArea from '../TextArea';
import Spinner from '../../components/Spinner';
import Tags from '../../components/forms/Tags';
import { isEqual } from '../../utils/validators';
import FormLabel from '../../components/forms/FormLabel';
import ClipboardTooltip from '../ClipboardTooltip';
import ColorAutocomplete from '../ColorAutocomplete';
import StringBuilder from './StringBuilder';
import { MultiCheckbox } from '../MultiCheckbox';
import RichTextField from './RichTextField';
import { ConceptSelectionInline } from './ConceptSelectionInline';

const UncheckedCheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

export default function FormSectionWFields({
  fields,
  withButtons = false,
  submitting,
  pristine,
  onCancel,
  disablePadding,
  columnJustifyContent = 'flex-start',
  columnSpacing = 2,
  activeUsersOnly = true,
  submitButtonLabel,
  hideCancelButton = false,
}) {
  const { t: tg } = useTranslation('general');
  const theme = useTheme();
  const formState = useFormState();
  const platformStore = useContext(PlatformContext);
  const [revealValue, setRevealValue] = useState({});
  const clientCode = platformStore?.currentClient?.code;
  const isUppticUser = platformStore?.currentUser?.isUppticUser;
  // For multiple columns in 1 section, set each fields' columnIndex to choose the column it is displayed in
  // columnIndex = 0 by default and references the first column
  // not setting a columnIndex on any field results in 1 column
  /*   const fields = [
    {
      name: 'name',
      type: 'text', // optional
      label: 'Application Name',
      placeholder: 'Enter Application Name',
      isRequired: false,
      isDisabled: false,
      width: 0.5,
      validate: validators.required,
      formatter: formatters.trim,
    },
    {
      name: 'status',
      type: 'select', // optional
      label: 'Application Status',
      isRequired: false,
      isDisabled: false,
      width: 0.5,
      options: [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }],
    },
    {
      name: 'subheadline',
      type: 'headline',
      label: 'My first subheadline',
    },
    {
      name: 'audit',
      type: 'audit',
    },
  ]; */

  const hasUsersField = useMemo(() => {
    const isUsersField = fields.some((field) => field?.type === 'users');
    return isUsersField;
  }, [fields]);

  const generateQueryParam = (clientCode, skip) => ({
    skip: !clientCode || !hasUsersField || skip,
    onError: () => {},
    context: {
      headers: { 'client-code': clientCode },
      handleError: true,
    },
    fetchPolicy: 'no-cache',
    variables: {
      clientCode,
      filter: activeUsersOnly
        ? { status: { $eq: 'active' } }
        : {},
    },
  });

  const { data: clientUsers, loading: clientUsersLoading } = useQuery(clientUsersGql, generateQueryParam(clientCode));
  const { data: uppticUsers, loading: uppticUsersLoading } = useQuery(clientUsersGql, generateQueryParam('upptic', !isUppticUser));

  const joinedUsersData = useMemo(() => {
    const options = [];
    if (isUppticUser && !uppticUsers) return options;
    if (!clientUsers) return options;
    const allOptions = [...(uppticUsers?.clientUsers || []), ...clientUsers.clientUsers];
    if (allOptions && allOptions?.length !== 0) {
      for (const user of allOptions) {
        options.push({ user });
      }
    }
    const unique = uniqBy(options, 'user.emailAddress');
    return sortBy(unique, ['user.lastName', 'user.firstName']);
  }, [clientUsers, uppticUsers, isUppticUser]);

  const renderFormField = (field, meta, input) => {
    if (field.type === 'customComponent') return field.component;

    if (field.type === 'select') {
      return (
        <Select
          {...input}
          key={field.name}
          label={field.label}
          options={field.options}
          error={meta.touched && meta.error}
          errorText={meta.error}
          disabled={field.isDisabled}
          readOnly={field.isReadOnly}
          labelKey={field.labelKey}
          valueKey={field.valueKey}
          multiple={!!field.multiple}
          defaultValue={field.defaultValue}
          checkbox={field.checkbox}
          renderValue={field.renderValue}
          allOption={field.allOption}
          onChange={(val) => input.onChange(val)}
        />
      );
    }

    if (field.type === 'autocomplete') {
      return (
        <Autocomplete
          {...input}
          key={field.name}
          options={field.options || []}
          groupBy={field.groupBy}
          renderGroup={(params) => (
            <li
              key={params.key}
            >
              <GroupHeader theme={theme}>
                {field.getGroupLabel
                  ? field.getGroupLabel(params.group)
                  : params.group}
              </GroupHeader>
              <GroupItems>
                {params?.children}
              </GroupItems>
            </li>
          )}
          getOptionLabel={field.getOptionLabel || ((option) => (option[field.labelKey] ? option[field.labelKey] : ''))}
          getOptionDisabled={field.getOptionDisabled}
          isOptionEqualToValue={(option, value) => {
            if (field.isOptionEqualToValue) {
              return field.isOptionEqualToValue(option, value);
            }
            return option === value;
          }}
          disabled={field.isDisabled}
          readOnly={field.isReadOnly}
          placeholder={field.placeholder}
          onChange={(_event, newValue) => input.onChange(newValue)}
          onInputChange={field.onInputChange}
          loading={field.loading}
          noOptionsText={field.noOptionsText}
          renderTags={field.renderTags}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              key={option.id || option.code || option.value}
            >
              {field.multiple && field.checkbox && (
                <Checkbox
                  icon={UncheckedCheckboxIcon}
                  checkedIcon={CheckedCheckboxIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
              {field.getOptionInListLabel ? field.getOptionInListLabel(option) : option[field.labelKey]}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={handleError(meta)}
              label={field.label}
              helperText={handleError(meta)}
              variant="filled"
            />
          )}
          sx={{
            '& .MuiInputLabel-root': {
              color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
            },
          }}
          disableClearable={field.disableClearable}
          multiple={field.multiple}
          fullWidth
        />
      );
    }

    if (field.type === 'users') {
      return (
        <UsersAutocomplete
          {...input}
          name={field.name}
          placeholder={field.placeholder}
          label={field.label}
          multiple={field.multiple}
          disableClearable={field.disableClearable}
          error={handleError(meta)}
          loading={clientUsersLoading || uppticUsersLoading}
          options={joinedUsersData}
          meta={meta}
          disabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'colors') {
      return (
        <ColorAutocomplete
          {...input}
          name={field.name}
          placeholder={field.placeholder}
          label={field.label}
          multiple={field.multiple}
          disableClearable={field.disableClearable}
          error={handleError(meta)}
          meta={meta}
          disabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'inputSelect') {
      return (
        <TextField
          {...input}
          select
          id={field.name}
          key={field.name}
          helperText={handleError(meta)}
          placeholder={field.placeholder}
          disabled={field.isDisabled}
          readOnly={field.readOnly}
          error={handleError(meta)}
          onChange={(event) => {
            input.onChange(event.target.value);
          }}
          InputProps={field.inputProps}
          fullWidth
          label={field.label}
          sx={{
            '& .MuiInputLabel-root': {
              color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
            },
          }}
        >
          {field.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (field.type === 'country') {
      return (
        <CountrySelect
          {...input}
          meta={meta}
          key={field.name}
          error={handleError(meta)}
          helperText={handleError(meta)}
          disabled={field.isDisabled}
          readOnly={field.readOnly}
          filter={field.filter}
          label={field.label}
          onChange={(val) => input.onChange(val?.value)}
          disableClearable={field.disableClearable}
          {...field.inputProps}
        />
      );
    }

    if (field.type === 'locale') {
      return (
        <LocaleSelect
          {...input}
          meta={meta}
          key={field.name}
          error={handleError(meta)}
          helperText={handleError(meta)}
          disabled={field.isDisabled}
          readOnly={field.readOnly}
          label={field.label}
          onChange={(val) => input.onChange(val?.value)}
          disableClearable={field.disableClearable}
          {...field.inputProps}
        />
      );
    }

    if (['percentage', 'currency', 'number'].includes(field.type)) {
      let inputSufix = null;
      if (field.type === 'percentage') inputSufix = '%';
      if (field.type === 'currency') inputSufix = '$';
      return (
        <TextField
          {...input}
          id={field.name}
          key={field.name}
          helperText={handleError(meta)}
          type="number"
          placeholder={field.placeholder}
          disabled={field.isDisabled}
          readOnly={field.readOnly}
          error={handleError(meta)}
          onChange={(event) => {
            input.onChange(event.target.value);
          }}
          InputProps={{
            startAdornment: field.inputPrefix || inputSufix
              ? <InputAdornment position="start">{field.inputPrefix || inputSufix}</InputAdornment>
              : undefined,
            inputProps: {
              ...field.inputProps,
            },
          }}
          fullWidth
          variant="filled"
          label={field.label}
          sx={{
            '& .MuiInputLabel-root': {
              color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
            },
          }}
        />
      );
    }

    if (field.type === 'date') {
      return (
        <DatePicker
          {...input}
          meta={meta}
          value={input.value}
          key={field.name}
          helperText={meta.touched ? meta.error : undefined}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          label={field.label}
          onChange={input.onChange}
          minDate={field.minDate}
          maxDate={field.maxDate}
          shouldDisableDate={field.shouldDisableDate}
          isClearable={field.isClearable}
        />
      );
    }

    if (field.type === 'month') {
      return (
        <DatePicker
          {...input}
          meta={meta}
          value={input.value}
          key={field.name}
          views={['month', 'year']}
          helperText={meta.touched ? meta.error : undefined}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          label={field.label}
          onChange={(item) => {
            const parsedDate = moment.utc(item);
            if (!parsedDate.isValid()) {
              input.onChange(item);
              return;
            }

            const value = parsedDate.startOf('month').toISOString();
            input.onChange(value);
          }}
          minDate={field.minDate}
          isClearable={field.isClearable}
        />
      );
    }

    if (field.type === 'dateRange') {
      return (
        <DateRangePicker
          {...input}
          meta={meta}
          value={input.value}
          key={field.name}
          fullWidth
          startLabel={field.startLabel}
          endLabel={field.endLabel}
          helperText={meta.touched ? meta.error : undefined}
          error={meta.touched && meta.error}
          disabled={field.isDisabled}
          disableFuture={field.disableFuture}
          startInputProps={field.startInputProps}
          endInputProps={field.endInputProps}
          onChange={(values) => input.onChange(values.map((value) => {
            if (value === null) return value;
            const date = new Date(value);
            return !Number.isNaN(date.getTime()) ? date.toISOString() : date;
          }))}
          minDate={field.minDate}
        />
      );
    }

    if (field.type === 'html') {
      return (
        <HTMLInclude
          string={field.string}
          key={field.name}
        />
      );
    }

    if (field.type === 'indicator') {
      return (
        <Indicator
          key={field.name}
          color={field.color}
          subType={field.subType}
          value={input.value}
          emptyText={field.emptyText}
          preceedingText={field.preceedingText}
        />
      );
    }

    if (field.type === 'buttonWDynamicText') {
      return (
        <FormButtonWDynamicText
          preceedingText={field.preceedingText}
          number={field.number}
          onClick={field.onClick}
          buttonName={field.buttonName}
          disabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'switch') {
      return (
        <Box
          key={field.name}
          display={field.display}
          justifyContent={field.justifyContent}
          alignItems={field.alignItems}
        >
          <FormControlLabel
            control={<Switch />}
            {...input}
            label={field.label}
            checked={input.value || input.checked}
            labelPlacement={field.labelPlacement || 'start'}
            disabled={field.isDisabled}
            readOnly={field.readOnly}
            error={meta.touched && meta.error ? 'true' : 'false'}
            sx={{ marginTop: '8px', marginLeft: '0px' }}
          />
          {meta.error && meta.touched ? (
            <FormHelperText>{meta.error}</FormHelperText>
          ) : null}
        </Box>
      );
    }

    if (field.type === 'checkbox') {
      return (
        <Box
          key={field.name}
          display={field.display}
          justifyContent={field.justifyContent}
          alignItems={field.alignItems}
        >
          <FormControlLabel
            control={<Checkbox />}
            {...input}
            label={field.label}
            checked={input.value || input.checked}
            disabled={field.isDisabled}
            readOnly={field.readOnly}
            error={meta.touched && meta.error ? 'true' : 'false'}
            sx={{ marginTop: '8px' }}
          />
          {meta.error && meta.touched ? (
            <FormHelperText>{meta.error}</FormHelperText>
          ) : null}
        </Box>
      );
    }

    if (field.type === 'multiCheckbox') {
      return (
        <MultiCheckbox
          input={input}
          meta={meta}
          name={field.name}
          groupLabel={field.groupLabel}
          checkboxes={field.checkboxes}
          onChange={(valArray) => input.onChange(valArray)}
          orientation={field.orientation}
          labelPlacement={field.labelPlacement}
        />
      );
    }

    if (field.type === 'icon') {
      return (
        input.value ? (
          <Box
            component="img"
            height={112}
            width={112}
            src={input.value}
          />
        ) : (
          <Box
            height={112}
            width={112}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ImageNotSupportedIcon sx={{ fontSize: '100px' }} />
          </Box>
        )
      );
    }

    if (field.type === 'buttons') {
      const alignmentSx = {
        center: {
          display: 'flex',
          justifyContent: 'center',
        },
      };
      const renderButton = (button, index) => {
        if (button.externalLink) {
          return (
            <Button
              key={index}
              variant="contained"
              href={button.externalLink}
              target={button.target}
              sx={{
                minWidth: '130px',
                minHeight: '56px',
              }}
            >
              {button.name}
            </Button>
          );
        }
        if (button.link) {
          return (
            <Button
              key={index}
              component={Link}
              variant="contained"
              to={button.link}
              sx={{
                minWidth: '130px',
                minHeight: '56px',
              }}
            >
              {button.name}
            </Button>
          );
        }
        return (
          <Button
            key={index}
            variant="contained"
            disabled={button.disabled}
            onClick={button.onClick}
            sx={{
              minWidth: '130px',
              minHeight: '56px',
            }}
          >
            {button.name}
          </Button>
        );
      };
      return (
        <Box
          sx={alignmentSx?.[field?.alignment]}
        >
          {field.buttons.map(renderButton)}
        </Box>
      );
    }

    if (field.type === 'optionBuilder') {
      return (
        <OptionBuilder
          {...input}
          key={field.name}
          error={meta.touched && meta.error}
          onChange={(value) => (field.onChange
            ? field.onChange(value)
            : input.onChange(value)
          )}
          boxSize={field.boxSize}
          containerSize={field.containerSize}
          rowFields={field.rowFields}
          value={input.value}
          data={field.data}
          label={field.label}
          onRowSelect={field.onRowSelect}
          marked={field.marked}
          customAddRowFields={field.customAddRowFields}
          customDelete={field.customDelete}
        />
      );
    }

    if (field.type === 'dualList') {
      return (
        <DualList
          {...input}
          options={field.options}
          valueField={field.valueField}
          labelField={field.labelField}
          selected={field.selected ? field.selected : input.value}
          error={meta.touched && meta.error}
          onChange={(value) => (field.onChange
            ? field.onChange(value)
            : input.onChange(value)
          )}
          reversed={field.reversed}
          selectedLabel={field.selectedLabel}
          optionsLabel={field.optionsLabel}
          selectedReorderable={field.selectedReorderable}
          height={field.height}
          sortSelected={field.sortSelected}
          disabled={field.disabled}
        />
      );
    }

    if (field.type === 'singleUpload') {
      return (
        <PreviewWrapper>
          {field.isDisabled && !input.value ? (
            <DisabledPlaceholder>
              {field.disabledPlaceholderText || tg('disabledPlaceholderText')}
            </DisabledPlaceholder>
          ) : (
            <SingleUploadInput
              placeholder={field.placeholder}
              onUpload={(files) => {
                input.onChange(files[0]);
              }}
              accept={field.accept}
              maxFiles={field.maxFiles}
              disabled={field.isDisabled}
              fileActions={field.fileActions}
              fileType={field.fileType}
              file={input.value}
              onRemove={() => {
                input.onChange(null);
              }}
            />
          ) }
        </PreviewWrapper>
      );
    }

    if (field.type === 'textarea') {
      return (
        <TextArea
          {...input}
          error={meta.touched && meta.error}
          key={field.name}
          minRows={field.minRows}
          maxRows={field.maxRows}
          placeholder={field.placeholder}
          isDisabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'tags') {
      if (field.loading) {
        return (
          <Spinner
            style={{ marginTop: 0 }}
          />
        );
      }
      return (
        <Tags
          {...input}
          tags={input.value}
          handleDelete={(i) => {
            const filteredTags = input.value.filter((tag, index) => index !== i);
            input.onChange(filteredTags);
          }}
          handleAddition={(tag) => {
            if (!tag.code) {
              field.handleTagCreate(tag)
                .then((res) => {
                  input.onChange([...input?.value || [], { ...tag, code: res.code, color: res.color }]);
                });
              return;
            }
            input.onChange([...input?.value || [], tag]);
          }}
          suggestions={field.suggestions}
          placeholder={field.placeholder}
          disabled={field.isDisabled}
          useMuiTheming
        />
      );
    }

    if (field.type === 'stringBuilder') {
      return (
        <StringBuilder
          input={input}
          meta={meta}
          buttons={field.buttons}
          textFieldLabel={field.textFieldLabel}
          buttonSectionLabel={field.buttonSectionLabel}
          id={field.name}
          helperText={handleError(meta)}
          error={handleError(meta)}
          disabled={field.isDisabled}
        />
      );
    }

    if (field.type === 'richTextarea') {
      return (
        <RichTextField
          value={input.value}
          onChange={(htmlString) => input.onChange(htmlString)}
          isDisabled={field.isDisabled}
          error={meta.touched && meta.error}
          minRows={field.minRows}
        />
      );
    }

    if (field.type === 'conceptSelector') {
      return (
        <ConceptSelectionInline
          onSelect={(selectionArray) => {
            if (selectionArray?.length !== 0) {
              input.onChange(selectionArray[0]);
              return;
            }
            input.onChange('');
          }}
          selectedValue={input.value}
          error={meta.touched && meta.error}
        />
      );
    }

    // eslint-disable-next-line prefer-destructuring
    let type = field.type;
    if (field.revealOption) {
      if (!field.inputProps) field.inputProps = {};
      field.inputProps.endAdornment = (
        <>
          <InputAdornment
            position="end"
          >
            <IconButton
              onClick={() => setRevealValue((state) => ({ ...state, [field.name]: !state[field.name] }))}
            >
              {revealValue[field.name] ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
            </IconButton>
          </InputAdornment>
          {field.copyToClipboardOption ? <ClipboardTooltip value={input.value} /> : null}
        </>
      );
    }
    if (field.revealOption) type = revealValue[field.name] ? 'text' : 'password';
    if (field.icon) {
      if (!field.inputProps) field.inputProps = {};
      field.inputProps.endAdornment = (
        <>
          <InputAdornment
            position="end"
          >
            <IconButton
              href={field.icon?.url}
              target={field.icon?.target}
            >
              {field.icon?.icon}
            </IconButton>
          </InputAdornment>
        </>
      );
    }

    return (
      <TextField
        {...input}
        id={field.name}
        key={field.name}
        helperText={handleError(meta)}
        type={type === 'link' ? 'url' : type}
        placeholder={field.placeholder}
        disabled={field.isDisabled}
        readOnly={field.readOnly}
        error={handleError(meta)}
        onChange={(event) => (field.onChange
          ? field.onChange({ event, input })
          : input.onChange(event.target.value))}
        InputProps={field.inputProps}
        fullWidth
        variant="filled"
        label={field.label}
        sx={{
          '& .MuiInputLabel-root': {
            color: meta.touched && meta.error ? theme.palette.text.error : `${theme.palette.text.secondary} !important`,
          },
        }}
      />
    );
  };

  const columns = {};

  for (const field of fields) {
    // eslint-disable-next-line no-continue
    if (field === undefined || field === null) continue;
    const columnIndex = field.columnIndex || 0;
    if (!columns[columnIndex]) columns[columnIndex] = [];
    columns[columnIndex].push(field);
  }

  const columnArray = Object.values(columns);

  const defaultXs = columnArray.length === 1 ? 6 : 12;
  let firstHeader = true;

  const renderColumn = (columnData, index) => (
    <Grid
      item
      flex="1 1 0"
      flexWrap="wrap"
      alignSelf="flex-start"
      key={`column-${index}`}
    >
      <Grid
        container
        spacing={columnSpacing}
        justifyContent={columnJustifyContent}
      >
        {columnData.map((field) => {
          if (field.type === 'audit') return null;
          if (
            field.isHidden
              && typeof field.isHidden === 'function'
              && field.isHidden(formState.values)
          ) return null;
          if ((field.isHidden && typeof field.isHidden !== 'function') || field.type === 'hidden') return null;
          if (field.type === 'headline') {
            const style = (firstHeader) ? { marginTop: 0 } : {};
            firstHeader = false;
            return (
              <Grid
                key={field.name}
                xs={field.xs || defaultXs}
                sm={field?.sm}
                md={field?.md}
                lg={field?.lg}
                xl={field?.xl}
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: '15px',
                    ...style,
                  }}
                >
                  {field.label}
                </Typography>
                <Divider variant="fullWidth" />
              </Grid>
            );
          }
          if (field.type === 'infoText') {
            return (
              <Grid
                key={field.name}
                xs={field.xs || defaultXs}
                sm={field?.sm}
                md={field?.md}
                lg={field?.lg}
                xl={field?.xl}
                item
              >
                <Typography textAlign={field.textAlign}>
                  {field.text}
                </Typography>
              </Grid>
            );
          }
          if (field.altLabelPrefix) {
            return (
              <Grid
                key={field.name}
                item
                xs={field.xs || defaultXs}
                sm={field?.sm}
                md={field?.md}
                lg={field?.lg}
                xl={field?.xl}
                display="flex"
              >
                {field.altLabelPrefix}
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
              </Grid>
            );
          }
          if (field.altLabelPostfix) {
            return (
              <Grid
                key={field.name}
                item
                xs={field.xs || defaultXs}
                sm={field?.sm}
                md={field?.md}
                lg={field?.lg}
                xl={field?.xl}
                display="flex"
              >
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
                {field.altLabelPostfix}
              </Grid>
            );
          }
          return (
            <Grid
              key={field.name}
              item
              xs={field.xs || defaultXs}
              sm={field?.sm}
              md={field?.md}
              lg={field?.lg}
              xl={field?.xl}
            >
              {field.type === 'textarea' || field.type === 'tags' || field.type === 'richText' ? (
                <FormLabel
                  name={field.name}
                  spacing={false}
                >
                  {field.label}
                </FormLabel>
              ) : null}
              {field.type === 'tags' ? (
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                  isEqual={(a, b) => isEqual(a, b, field.type)}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
              ) : (
                <Field
                  name={field.name}
                  validate={field.validate}
                  formatOnBlur
                  format={field.formatter /* || (field.type === 'text' && formatters.trim) */}
                  key={field.fieldKey}
                  type={field.type === 'checkbox' || field.type === 'switch' ? 'checkbox' : undefined}
                  isEqual={field.isEqual}
                >
                  {({ input, meta }) => renderFormField(field, meta, input)}
                </Field>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      p={disablePadding ? 0 : 2}
      justifyContent="flex-end"
    >
      <Grid
        item
        flexDirection="row"
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          {columnArray.map((fields, index) => renderColumn(fields, index))}
        </Grid>
      </Grid>
      {fields.map((field) => {
        if (field?.type === 'audit') {
          return (
            <Grid
              item
              xs={12}
              mt={1.5}
              key={field.name}
            >
              <Field
                name={field.name}
                validate={field.validate}
                formatOnBlur
                format={field.formatter}
              >
                {({ input }) => (
                  <AuditFields audit={input.value} />
                )}
              </Field>
            </Grid>
          );
        }
        return null;
      })}
      {withButtons && (
        <Grid
          container
          justifyContent="flex-end"
          marginTop={2}
        >
          {!hideCancelButton && (
            <Button
              onClick={onCancel}
              variant="contained"
              sx={{ marginLeft: 2 }}
            >
              {tg('cancel')}
            </Button>
          )}
          <Button
            disabled={submitting || pristine}
            variant="contained"
            type="submit"
            sx={{ marginLeft: 2 }}
          >
            {submitButtonLabel || tg('save')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

const PreviewWrapper = styled.div`
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisabledPlaceholder = styled.div`
  color: #fff;
  opacity: 0.5;
`;

const GroupHeader = styled.div`
  position: sticky;
  top: -8px;
  padding: 4px 10px;
  background: ${(props) => props.theme.palette.background.default}
`;

const GroupItems = styled.ul`
  padding: 0;
`;
